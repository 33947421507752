<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12">
      <div class="card-header justify-content-between">
        <div class="card-title">{{ $route.name }} List</div>
      </div>
      <div class="card-body">
        <div class="flex">
          <div class="w-1/2">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Transaction Date">
                <el-date-picker
                  v-model="search.date"
                  type="daterange"
                  size="small"
                  :picker-options="datePickerOptions"
                  class="w-full"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Search Keyword">
                <el-input clearable size="small" type="text" v-model="search.search_keyword" placeholder="Input search keyword" class="mr-2"/>
              </el-form-item>
              <el-form-item label="Filter Type">
                <el-select size="small" v-model="search.search_by" class="mr-2">
                  <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="ml-4 w-1/2">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Approval Status">
                <el-select size="small" v-model="approval_status_filter" placeholder="Approval Status" class="mr-2">
                  <el-option label="All" value=""/>
                  <el-option label="Waiting Approval" value="waiting_approval"/>
                  <el-option label="Approved" value="approved"/>
                  <el-option label="Rejected" value="rejected"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Refund Status">
                <el-select size="small" v-model="refund_status_filter" placeholder="Refund Status" class="mr-2">
                  <el-option label="All" value=""/>
                  <el-option label="Waiting Approval" value="waiting_approval"/>
                  <el-option label="Process" value="process"/>
                  <el-option label="Done" value="done"/>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
      </div>
      <div class="table-responsive" style="min-height: 50px">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="25%">Request Date</th>
              <th width="25%">Invoice Number</th>
              <th width="20%">User Name</th>
              <th width="20%">User Email</th>
              <th width="10%">Amount</th>
              <th>Approval Status</th>
              <th class="child1-sticky">Action</th>
            </tr>
          </thead>
          <tbody v-loading="is_loading">
            <tr v-for="(item, i) in list" :key="i">
              <td>{{ item.created_at_str }}</td>
              <td>{{ item?.order?.invoice_number || "-" }}</td>
              <td>{{ item.user.full_name }}</td>
              <td>{{ item.user.email }}</td>
              <td>{{ item.order.amount_str }}</td>
              <td>
                <span class="tag" :class="item.approval_status_color">{{ item.approval_status_str }}</span>
              </td>
              <td>
                <el-dropdown @command="handleCommand($event, item)" size="mini" type="info">
                  <el-button size="mini" type="info">
                    Action&nbsp;<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" class="px-2 py-2">
                    <el-dropdown-item class="py-1" command="handlerBtnDetail">
                      <div class="flex">
                        <span class="w-1/4"><font-awesome-icon icon="eye"/></span>
                        <span>View Detail</span>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <el-empty description="No more data" v-if="total_rows > 0 && list.length < per_page" :image="noMoreData"></el-empty> -->
        <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
        <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="per_page"
            :limit="5"
            :total-rows="total_rows"
            v-model="page"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import {
  GET_REFUND_REQUEST,
} from '../../store/modules/refund_request';
import emptyStateImage from '@/assets/images/empty-state.png';
import noMoreData from '@/assets/images/undraw/no-more-data.svg';

export default {
  name: 'RefundRequest',
  metaInfo: {
    title: 'Refund Request',
  },
  data() {
    return {
      approval_status_filter: '',
      refund_status_filter: '',
      total_rows: 0,
      emptyStateImage,
      noMoreData,
      page: 1,
      per_page: 20,
      list: [],
      is_loading: false,
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
      search: {
        search_keyword: '',
        search_by: 'invoice_number',
        date: [
          moment().subtract(3, 'month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
      },
      search_options: [
        { value: 'invoice_number', text: 'Invoice Number' },
        { value: 'full_name', text: 'Full Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
      ],
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
  },
  methods: {
    async getList() {
      this.is_loading = true;
      await this.$store.dispatch(GET_REFUND_REQUEST, {
        page: this.page,
        search_by: this.search.search_by,
        search_keyword: this.search.search_keyword,
      });
      this.is_loading = false;
      const { rows, count } = this.$store.getters.refund_request;
      this.total_rows = count;
      this.list = rows;
    },
    searchHandler() {
      this.page = 1;
      this.getList();
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    handlerBtnDetail(id) {
      this.$router.push({
        name: 'Refund Request Detail',
        params: {
          id,
        },
      });
    },
    handleCommand(action, item) {
      this[action](item.id);
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}
</style>